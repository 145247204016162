/* eslint-disable react/jsx-indent */

/* eslint-disable react/jsx-props-no-spreading */
import { useResizeObserver, useVisualViewport } from '@folklore/hooks';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import usePlaceholderItems from '../../hooks/usePlaceholderItems';
import useRecentSearch from '../../hooks/useRecentSearch';
import useSearch from '../../hooks/useSearch';
import * as AppPropTypes from '../../lib/PropTypes';

import useSearchPanelSpring from '../../animations/useSearchPanelSpring';
import BasicButton from '../buttons/BasicButton';
import SearchHorizontalCard from '../cards/SearchHorizontalCard';
import SearchField from '../fields/SearchField';
import SearchResultsList from '../lists/SearchResultsList';
import SearchSuggestionsList from '../lists/SearchSuggestionsList';
import PlaceholderText from '../partials/PlaceholderText';
import MenuSubSectionTitle from '../typography/MenuSubSection';

import styles from '../../styles/panels/search-panel.module.css';

const propTypes = {
    opened: PropTypes.bool,
    query: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    formRef: AppPropTypes.ref,
    onFocus: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
};

const defaultProps = {
    opened: false,
    query: null,
    className: null,
    contentClassName: null,
    formRef: null,
    onFocus: null,
    onCancel: null,
    onChange: null,
};

function SearchPanel({
    opened,
    query: initialQuery,
    className,
    contentClassName,
    formRef,
    onFocus,
    onCancel,
    onChange,
}) {
    const intl = useIntl();
    const [query, setQueryState] = useState(initialQuery);
    const {
        items: searchResults = null,
        isFetching: isFetchingResults,
        isFetched = false,
        update,
    } = useSearch(query);
    const recentSearch = useRecentSearch(isFetched ? query : null);
    const setQuery = useCallback(
        (newQuery) => {
            setQueryState(newQuery);
            if (onChange !== null) {
                onChange(newQuery);
            }
        },
        [onChange, setQueryState],
    );
    const onClickCancel = useCallback(() => {
        setQuery(null);
        if (onCancel !== null) {
            onCancel();
        }
    }, [setQuery, onCancel]);
    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            update();
        },
        [update],
    );
    const onFieldChange = useCallback(
        (newValue) => {
            if (newValue === null || newValue.length === 0) {
                setQuery(null);
            } else {
                setQuery(newValue);
            }
        },
        [setQuery],
    );

    const onClickRecentSearch = useCallback((e, newQuery) => {
        e.preventDefault();
        setQuery(newQuery);
    }, []);

    const hasQuery = query !== null;

    const {
        ref: cancelButtonRef,
        entry: { contentRect: cancelButtonRect = null },
    } = useResizeObserver();
    const { width: cancelButtonWidth = null } = cancelButtonRect || {};

    const { searchFieldStyle, cancelButtonStyle, sectionStyle } = useSearchPanelSpring({
        opened,
        hasQuery,
        cancelButtonWidth,
    });

    const offset = 20;
    const transitions = useTransition(searchResults || [], {
        from: { y: offset, opacity: 0 },
        enter: { y: 0, opacity: 1 },
        keys: ({ id }) => `${id}`,
    });

    const placeholderSearchResults = usePlaceholderItems(4);

    const { height: viewportHeight, offsetTop: viewportTop = 0 } = useVisualViewport();

    const [linksDisabled, setLinksDisabled] = useState(!opened);

    useEffect(() => {
        let timeout = null;
        if (opened && linksDisabled) {
            timeout = setTimeout(() => {
                setLinksDisabled(false);
            }, 200);
        } else if (!opened && !linksDisabled) {
            setLinksDisabled(true);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [opened]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened,
                    [styles.linksDisabled]: linksDisabled,
                    [className]: className !== null,
                },
            ])}
            style={{
                transform: opened ? `translateY(${viewportTop || 0}px)` : null,
                height: opened ? viewportHeight : null,
            }}
        >
            <div className={styles.inner}>
                <form className={styles.form} onSubmit={onSubmit} ref={formRef}>
                    <animated.div className={styles.fieldContainer} style={searchFieldStyle}>
                        <SearchField
                            value={query}
                            placeholder={intl.formatMessage({
                                defaultMessage: 'Recherche...',
                                description: 'Field placeholder',
                            })}
                            loading={isFetchingResults}
                            onChange={onFieldChange}
                            onFocus={onFocus}
                            className={styles.field}
                        />
                    </animated.div>

                    <animated.div
                        className={styles.cancelButtonContainer}
                        style={cancelButtonStyle}
                        ref={cancelButtonRef}
                    >
                        <BasicButton
                            type="button"
                            onClick={onClickCancel}
                            className={styles.cancelButton}
                        >
                            <FormattedMessage defaultMessage="Annuler" description="Button label" />
                        </BasicButton>
                    </animated.div>
                </form>
                {!hasQuery ? (
                    <div className={classNames([styles.content, contentClassName])}>
                        <animated.div
                            className={classNames([styles.section, styles.suggestions])}
                            style={sectionStyle}
                        >
                            <MenuSubSectionTitle className={styles.title}>
                                <FormattedMessage
                                    defaultMessage="Suggestions"
                                    description="Search panel heading"
                                />
                            </MenuSubSectionTitle>

                            <SearchSuggestionsList
                                // cardType="horizontal"
                                // cardSize="tiny"
                                // cardTheme="menu"
                                // presentation="grid"
                                // columns={1}
                                itemsClassName={styles.items}
                            />
                        </animated.div>
                        {recentSearch !== null && recentSearch.length > 0 ? (
                            <animated.div
                                className={classNames([styles.section, styles.recent])}
                                style={sectionStyle}
                            >
                                <MenuSubSectionTitle className={styles.title}>
                                    <FormattedMessage
                                        defaultMessage="Recherches récentes"
                                        description="Search panel heading"
                                    />
                                </MenuSubSectionTitle>
                                <div className={styles.items}>
                                    {recentSearch
                                        .toReversed()
                                        .slice(0, 5)
                                        .map((label) => (
                                            <SearchHorizontalCard
                                                query={label}
                                                className={styles.item}
                                                onClick={(e) => onClickRecentSearch(e, label)}
                                            />
                                        ))}
                                </div>
                            </animated.div>
                        ) : null}
                    </div>
                ) : (
                    <div className={classNames([styles.content, contentClassName])}>
                        {searchResults !== null ? (
                            transitions((style, { label, data: items, ...resultsProps }) => (
                                <animated.div
                                    className={classNames([styles.section, styles.results])}
                                    style={style}
                                >
                                    <MenuSubSectionTitle className={styles.title}>
                                        {label}
                                    </MenuSubSectionTitle>
                                    <SearchResultsList
                                        items={items}
                                        {...resultsProps}
                                        className={styles.items}
                                    />
                                </animated.div>
                            ))
                        ) : (
                            <animated.div className={classNames([styles.section, styles.results])}>
                                <MenuSubSectionTitle className={styles.title}>
                                    <PlaceholderText />
                                </MenuSubSectionTitle>
                                <SearchResultsList
                                    items={placeholderSearchResults}
                                    className={styles.items}
                                />
                            </animated.div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

SearchPanel.propTypes = propTypes;
SearchPanel.defaultProps = defaultProps;

export default SearchPanel;
